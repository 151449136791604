import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import styles from './index.module.scss';
import { Loading, Dropdown, SvgIcon, TooltipDisclaimer } from '@ui-elements';
import { toCamelCase } from '~/common/utils';
import { Button, IconEnum } from '~/common/components/ui-elements';
import stylesHeroCard from '../hero-card/index.module.scss';
import { CallToAction } from '~/common/models';
import cn from 'classnames';

type VariantAccessories = {
  header: string;
  description: string;
  cta: CallToAction[];
  pimIdentifier: string;
};

export const VariantAccessories = (props: VariantAccessories) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [displayedItems, setDisplayedItems] = useState(6);
  const [currentList, setCurrentList] = useState<any[]>([]);
  const [dataList, setDataList] = useState<any[]>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [selectedCategoryOption, setSelectedCategoryOption] = useState({
    label: 'All Categories',
    value: 'all',
  });

  useLayoutEffect(() => {
    if (containerRef.current !== null) {
      if (
        containerRef.current?.clientWidth < 391 ||
        containerRef.current?.clientWidth < 769 ||
        containerRef.current?.clientWidth < 1025
      ) {
        fetchAccessories(4);
        setIsMobile(true);
      } else {
        fetchAccessories(6);
        setIsMobile(false);
      }
    }
  }, []);

  useEffect(() => {
    if (selectedCategoryOption.value === 'all') {
      setCurrentList(dataList.slice(0, displayedItems));
    } else {
      setCurrentList(
        dataList
          .filter(
            (item) =>
              item.accessoryCategoryName === selectedCategoryOption.value
          )
          .slice(0, displayedItems)
      );
    }
  }, [displayedItems]);

  const fetchAccessories = (count) => {
    fetch(`/api/accessories/accessories/variant/${props.pimIdentifier}`)
      .then((res) => res.json())
      .then((res) => {
        setDataList(res);
        setCurrentList(res.slice(0, count));
        setIsLoading(false);
      });
  };

  const loadMore = () => {
    if (isMobile) {
      setDisplayedItems(currentList.length + 4);
    } else {
      setDisplayedItems(currentList.length + 6);
    }
  };

  const handleDropdownSelect = (value) => {
    setSelectedCategoryOption(value);
    if (value.value === 'all') {
      setCurrentList(dataList.slice(0, isMobile ? 4 : 6));
    } else {
      setCurrentList(
        dataList
          .filter((item) => item.accessoryCategoryName === value.value)
          .slice(0, isMobile ? 4 : 6)
      );
    }
    if (isMobile) {
      setDisplayedItems(4);
    } else {
      setDisplayedItems(6);
    }
  };

  const itemCount = () => {
    let count = 0;
    if (selectedCategoryOption.value === 'all') {
      count = dataList.length;
    } else {
      count = dataList.filter(
        (item) => item.accessoryCategoryName === selectedCategoryOption.value
      ).length;
    }
    return count;
  };

  const options = [
    {
      label: 'All Categories',
      value: 'all',
    },
    {
      label: 'Cargo & Tow',
      value: 'Cargo & Tow',
    },
    {
      label: 'Convenience & Safety',
      value: 'Convenience & Safety',
    },
    {
      label: 'Protection',
      value: 'Protection',
    },
    {
      label: 'Security',
      value: 'Security',
    },
    {
      label: 'STI Performance Parts',
      value: 'STI Performance Parts',
    },
    {
      label: 'Styling',
      value: 'Styling',
    },
  ];

  return (
    <div
      id="variant-accessories"
      className={styles.AccessoriesWrapper}
      ref={containerRef}
    >
      <div className={styles.HeaderContainer}>
        <div dangerouslySetInnerHTML={{ __html: props.header }} />
        <div dangerouslySetInnerHTML={{ __html: props.description }} />
      </div>
      {isLoading && <Loading height="100%" />}
      {!isLoading && (
        <>
          <div className={styles.AccessoriesGridHeader}>
            <p>
              <span style={{ fontWeight: 'bold' }}>{itemCount()}</span>
              &nbsp;items found
            </p>
            <Dropdown
              onChange={handleDropdownSelect}
              value={selectedCategoryOption}
              options={options}
              rounded={false}
            />
          </div>
          <div className={styles.AccessoriesGridContainer}>
            <div className={styles.AccessoriesGrid}>
              {currentList.map((data, index) => {
                return (
                  <div key={index} className={styles.AccessoriesTile}>
                    <img src={data.imageSrc} />
                    <div>
                      <div>
                        <span>{data.description}</span>
                      </div>
                      <span
                        className={styles.FittedPrice}
                      >{`Fitted RRP:${data.priceFitted.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}`}</span>
                    </div>
                    <div className={styles.CTAContainer}>
                      {props.cta?.map((cta, idx) => {
                        const model =
                          window.location.pathname.split('/')[1] ?? '';
                        const buttonProps: any = {
                          type: toCamelCase(cta.ctaButtonType),
                          href: `/${model}/accessories/${data.accessoryCategoryName
                            .replace(/\s+/g, '-')
                            .toLowerCase()}/${data.description
                            .replace(/\s+/g, '-')
                            .toLowerCase()}`,
                          target: cta.ctaUrl?.target ?? '_self',
                          onClick: () => {},
                        };
                        return (
                          <Button
                            key={idx}
                            className={cn(
                              stylesHeroCard.HeroCardCTA,
                              stylesHeroCard[`HeroCardCTA${buttonProps.type}`]
                            )}
                            {...buttonProps}
                          >
                            {cta.ctaIcon && cta.ctaIcon !== 'none' && (
                              <SvgIcon
                                className={styles.HeroCardCTAIcon}
                                type={cta.ctaIcon as keyof typeof IconEnum}
                                color="#1637a0"
                                size={1.2}
                                strokeWidth={2}
                              />
                            )}
                            <span
                              style={
                                buttonProps.type === 'text'
                                  ? { color: '#1637a0' }
                                  : {}
                              }
                            >
                              {cta.ctaUrl?.name || ''}
                            </span>
                          </Button>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={styles.ViewMoreContainer}>
              {itemCount() > currentList.length && (
                <span onClick={loadMore}>
                  View more{' '}
                  <SvgIcon
                    type="chevronDown"
                    size={1}
                    style={{
                      marginLeft: '3px',
                      verticalAlign: 'top',
                    }}
                  />
                </span>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
